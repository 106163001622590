import type { MetaFunction } from '@remix-run/react'
import { useMutation } from '@tanstack/react-query'
import { type FormEvent, useRef, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ValidationError, showToast, useAuth } from '@IJGN/potential'
import { useSharedData } from '~/context/SharedDataProvider'
import login from '~/feature/auth/api/login'
import type { LoginParams } from '~/feature/auth/types'
import { DISPLAY_CANCELED_SURVEYS_KEY } from '~/lib/localStorage'
import LogoPath from '../images/logo/logo-potential-cloud.png'
import { SITE_TITLE } from '../lib/constants'
import { loginErrorMessage } from '../lib/message'
import { handleBackendValidationErrors, handleValidationErrors } from '../lib/validation'
import { CompanyPersonLoginSchema } from '../models/company_person'
import SubmitButton from './components/Button/SubmitButton'
import InputPassword from './components/Input/Password'

export const meta: MetaFunction = () => {
  return [{ title: `ログイン | ${SITE_TITLE}` }]
}

export default function Login() {
  const formRef = useRef<HTMLFormElement>(null)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string
  }>({})
  const [loading, setLoading] = useState(false)

  const { refreshAuth } = useAuth()
  const { fetchData } = useSharedData()

  const loginMutation = useMutation({
    mutationFn: async (params: LoginParams) => login(params),
    onSuccess: async () => {
      fetchData()
      refreshAuth()
    },
    onError: error => {
      let errorMessage = loginErrorMessage
      if (error instanceof ValidationError) {
        errorMessage = handleBackendValidationErrors(error)
      }
      setMessage(errorMessage)
      setLoading(false)
    },
  })

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setMessage('')
    setValidationErrors({})
    setLoading(true)

    const validation = CompanyPersonLoginSchema.safeParse({ email, password })
    if (!validation.success) {
      const validationErrors = handleValidationErrors(validation.error)
      setValidationErrors(validationErrors)
      showToast.error('入力項目に誤りがあります。')

      // エラークラスが付与されてからスクロール移動
      requestAnimationFrame(() => {
        const firstErrorField = formRef.current?.querySelector('.validation-error-section')
        if (firstErrorField) {
          firstErrorField.scrollIntoView({ behavior: 'smooth' })
        }
      })
      setLoading(false)
      return
    }

    loginMutation.mutate({
      email,
      password,
    })

    localStorage.removeItem(DISPLAY_CANCELED_SURVEYS_KEY)
  }

  return (
    <main className='l-management-screen__main bg-knowledge-navy-5 flex flex-col gap-[50px] justify-center items-center p-10 w-full h-full relative before:absolute before:content-[""] before:w-full before:h-5 before:top-0 before:right-0 before:z-10 before:bg-gradient-to-r before:from-potential-blue-1 before:to-excellent-red-1'>
      <img src={LogoPath} alt='POTENTIAL CLOUD' className='w-[274px] block' />
      <form
        ref={formRef}
        onSubmit={handleLogin}
        className='shadow-xl bg-white rounded-3xl overflow-hidden w-full max-w-2xl'
        autoComplete='off'
      >
        <div className='block p-10 w-full'>
          <h1 className='text-xl text-center font-bold mb-5'>ログイン</h1>
          <label
            htmlFor='Username'
            className={`block text-sm font-bold mb-2.5 cursor-default ${
              validationErrors?.email ? 'validation-error-section' : ''
            }`}
          >
            ログインID
          </label>
          <div className='relative'>
            <span className='absolute top-[50%] left-4 z-10 translate-y-[-50%] icon-regular icon-envelope flex items-center justify-center icon-before text-sm text-gray-3 pointer-events-none' />
            <input
              type='text'
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder='Username'
              className='block w-full border border-solid rounded-lg py-2.5 pr-2.5 pl-10 text-black placeholder:text-gray-3 border-gray-4 focus:border-black'
              autoComplete='off'
            />
          </div>
          {validationErrors?.email && (
            <p className='text-notification-false-1-2 text-sm font-bold mt-2'>{validationErrors.email}</p>
          )}
          <label
            htmlFor='Password'
            className={`block text-sm font-bold mb-2.5 cursor-default mt-4 ${
              validationErrors?.password ? 'validation-error-section' : ''
            }`}
          >
            パスワード
          </label>
          <div className='relative'>
            <span className='absolute top-[50%] left-4 z-10 translate-y-[-50%] icon-regular icon-key flex items-center justify-center icon-before text-sm text-gray-3 pointer-events-none' />
            <InputPassword
              value={password}
              name=''
              onChange={e => setPassword(e.target.value)}
              placeholder='Password'
              className='block w-full border border-solid rounded-lg py-2.5 pr-2.5 pl-10 text-black placeholder:text-gray-3 border-gray-4 focus:border-black'
            />
          </div>
          {validationErrors?.password && (
            <p className='text-notification-false-1-2 text-sm font-bold mt-2'>{validationErrors.password}</p>
          )}
          {message && <p className='text-red-500 text-sm font-bold mt-4 validation-error-section'>{message}</p>}
          <div className='flex justify-end mt-4'>
            <a href='/forgot_password' className='flex justify-center items-center gap-2.5 text-knowledge-navy-2'>
              <span className='icon-solid icon-circle-question relative flex items-center justify-center icon-before text-sm' />
              <span className='text-sm font-bold'>パスワードを忘れた方はこちら</span>
            </a>
          </div>
        </div>
        <div className='flex justify-end items-center gap-2.5 p-5 bg-gray-5 border-t border-solid border-gray-4'>
          <SubmitButton label='ログイン' loading={loading} colorVariant='link-move' largeSize />
        </div>
      </form>
    </main>
  )
}
