import { LoginParams, MessageResponse } from '~/feature/auth/types'
import { getApiClient } from '~/lib/apiClientSingleton'

const login = async (params: LoginParams) => {
  const endpoint = '/session'
  const apiClient = getApiClient()
  return await apiClient.fetchFromAPI<Promise<MessageResponse>>({
    endpoint,
    options: apiClient.postRequestOptions(params),
  })
}

export default login